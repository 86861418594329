import {BaseAuthService} from "../../../authentication/check-auth"

export function BlueGraceAuthService() {
    let baseAuthService = new BaseAuthService()

    return {
        isAuthenticated() {
            if (baseAuthService.isAuthenticated()) {
                return true
            } else {
                return false
            }
        },
        onBeforeApiCall(requestObj) {
            let returnObj = baseAuthService.onBeforeApiCall(requestObj)
            return returnObj
        },
        getUser() {
            // return userId, role, userType, username, name, carrier { companyName, mc, dot, externalId, carrierId, contact: { email, phone, ext}}
            let userObj = baseAuthService.getUser()
            return userObj
        },
        login(token) {
           // might return promise
           if (token) {
            return baseAuthService.login(token)
        }
        },
        logout() {
            // logout
            baseAuthService.logout()
        },
        getSignupPath() {
            return "www.truckertools.com" // TODO: update
        }
    }
}